import React from 'react';
import { server } from "../../server";

class ProductComponent extends React.Component {
  updateDiscountPrice = async () => {
    const { product } = this.props;
    const newDiscountPrice = prompt("Enter new discount price for " + product.name + ":");

    if (newDiscountPrice) {
      try {
        const response = await fetch(`${server}/product/products/${product._id}/discount`, {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ discountPrice: newDiscountPrice }),
        });
        const updatedProduct = await response.json();
        // console.log('Product updated:', updatedProduct);
        
        if (this.props.onProductUpdate) {
          this.props.onProductUpdate(updatedProduct);
        }
      } catch (error) {
        console.error('Error updating product:', error);
      }
    }
  };

  render() {
    const { product } = this.props;
    return (
      <div className="product">
        <h2>{product.name}</h2>
        <p>{product.description}</p>
        
        <button onClick={this.updateDiscountPrice}>Update Discount</button>
      </div>
    );
  }
}

export default ProductComponent;