import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { server } from "../../server";
import { toast } from "react-toastify";

function Withdraw({ userId }) {
  const [withdrawalMethods, setWithdrawalMethods] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState('');
  const [amount, setAmount] = useState(0);

  // Fetch withdrawal methods
  useEffect(() => {
    const fetchMethods = async () => {
      try {
        const response = await axios.post(`${server}/user/get-withdrawal-method`, { userId });
       
        setWithdrawalMethods(response.data.data);
      } catch (error) {
        console.error("Failed to fetch withdrawal methods", error);
        toast.error('Failed to fetch withdrawal methods');
      }
    };
    
    fetchMethods();
  }, [userId]);

  const handleWithdraw = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${server}/user/withdraw`, {
        userId,
        methodId: selectedMethod, 
        amount,
      });
      toast.success('Withdrawal request submitted successfully');
    } catch (error) {
      console.error("Failed to process withdrawal", error);
      toast.error('Failed to process withdrawal or Rating limit not reached!');
    }
  };

  return (
    <div className="max-w-md mx-auto my-10 bg-white p-8 border border-gray-300 rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">Withdraw Funds</h2>
      <form onSubmit={handleWithdraw}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Withdrawal Method</label>
          <select
            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={selectedMethod}
            onChange={(e) => setSelectedMethod(e.target.value)}
            required
          >
            <option value="">Select a method</option>
            {withdrawalMethods.map((method) => (
              <option key={method._id} value={method._id}>
                {method.methodType}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Amount</label>
          <input
            type="number"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>
        <div className="flex items-center justify-between">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
            Withdraw
          </button>
        </div>
      </form>
    </div>
  );
}

export default Withdraw;