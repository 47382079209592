import React, {useEffect, useState} from "react";
import axios from "axios";
import { server } from "../../../server";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';


// function SampleNextArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", background: "red" }}
//       onClick={onClick}
//     />
//   );
// }

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", background: "green" }}
//       onClick={onClick}
//     />
//   );
// }



const ShopsCard = () => {
  const [shops, setShops] = useState([]);
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };

  useEffect(() => {
    const fetchAllShops = async () => {
      try {
        const response = await axios.get(`${server}/shops`);
        setShops(response.data);
        // console.log(response.data)
      } catch (error) {
        console.error("Error fetching shops:", error);
        // Handle error appropriately
      }
    };

    fetchAllShops();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  

  //  <img src="https://cdn-icons-png.flaticon.com/512/6978/6978223.png" height={100} width={100} alt="" />
  return (

  <div className="slider-container">
      <Slider {...settings} >
    {shops.map((shop, index) => (
         <Link to={`${shop.url}`}  className="inline-block"> 
          
        <div key={index} className="card m-1" onClick={scrollToTop}> {/* Added margin for spacing */}
          <div className="content">
            <div className="back">
              <div className="back-content">
                <img src={shop.shopImage} height={100} width={100} alt={shop.shopImage} />
                <strong className="uppercase">{shop.shopName}</strong>
                <strong className="text-green-500 text-right lowercase">{shop.shopStatus}</strong>

              </div>
            </div>
            <div className="front">
              <div className="img">
                <div className="circle"></div>
                <div className="circle" id="right"></div>
                <div className="circle" id="bottom"></div>
              </div>
              <div className="front-content">
                <small className="badge">{shop.shopBadge}</small> {/* Assuming fixed value; update as needed */}
                <div className="description">
                  <div className="title">
                    <p className="title">
                      <strong>{shop.shopLocation}</strong>
                    </p>
                  </div>
                  <p className="card-footer ">
                   open days/hours &nbsp; | &nbsp;  {shop.shopTime}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
         </Link>
      ))}
      
    </Slider>
  </div>
  );
  
};

export default ShopsCard;
