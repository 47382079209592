import React from "react";
import { FiMinus } from "react-icons/fi";
import {
  FaYoutube,
  FaArrowCircleRight,
  FaTwitter,
  FaFacebook,
} from "react-icons/fa";
import { Link } from "react-router-dom";

function AdsContent() {
  return (
    <section>
      <div>
        <div className="container text-center mt-5 shadow p-5 mb-5">
          <h3 className="fs-2 container text-center ">2 million people have chosen</h3>
          <h3 className="fs-2 container text-center">AdSense, here's why</h3>
          {/* section 1 */}
          <div className="ads-parent-container container mt-5">
            <div className="ads-child-container1 container">
              <div>
                <div className="img-container rounded-circle p-5">
                  <img
                    src="img/card.png"
                    alt="visa-card-icon"
                    width={120}
                    style={{ marginLeft: "-20px" }}
                    
                  />
                </div>
                <h5 className="text-start mb-3 mt-5">
                  Earn money from your site
                </h5>
                <p className="text-start">
                  Millions of advertisers compete for your ad space. That means
                  more money, more relevant ads, and more ad spaces filled.
                </p>
              </div>
            </div>
            <div className="ads-child-container2 container">
              <div>
                <div className="img-container rounded-circle p-3">
                  <img
                    src="img/optimised-mobile.png"
                    style={{ marginLeft: "0px" }}
                    alt="visa-card-icon"
                  />
                </div>
                <h5 className="text-start mb-3 mt-5">
                  Ads optimized for mobile
                </h5>
                <p className="text-start">
                  Google can optimize the size of your ad units to automatically
                  fit desktop or mobile, meaning there's more chance they'll be
                  seen and clicked.
                </p>
              </div>
            </div>
            <div className="ads-child-container3 container">
              <div>
                <div className="img-container rounded-circle p-4">
                  <img
                    src="img/time-icon.png"
                    width={120}
                    style={{ marginLeft: "0px" }}
                    alt="visa-card-icon"
                  />
                </div>
                <h5 className="text-start mb-3 mt-5">Save Time</h5>
                <p className="text-start">
                  Add one piece of code to your site and Google will
                  automatically show ads tailored to your site's layout, saving
                  you time making changes to ad code.
                </p>
              </div>
            </div>
          </div>
        </div>
       {/* section 2 */}
       <div className="pt-5 bg-gray-50 pb-5">
          <div className="container mx-auto">
            <h6 className="text-uppercase text-lg font-medium text-center">Drive revenue</h6>
            <h3 className="text-center text-2xl md:text-3xl mt-3">See how much you could</h3>
            <h3 className="text-center text-2xl md:text-3xl">earn from AdSense</h3>
            <p className="text-center mt-5">
              To view your potential earnings, select a category for your <br />
              site and a region for your site's visitors.
            </p>
            {/* forms */}
            <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
              <select className="form-select w-full p-3 border rounded-lg bg-white">
                <option selected>Select a region</option>
                <option value="1">Asia and Pacific Countries</option>
                <option value="2">Europe, Middle East and Africa</option>
                <option value="3">North America</option>
                <option value="4">South America</option>
              </select>
              <select className="form-select w-full p-3 border rounded-lg bg-white">
                <option selected>Select a category</option>
                <option value="1">Arts & Entertainment</option>
                <option value="2">Autos & Vehicles</option>
                <option value="3">Beauty & Fitness</option>
                <option value="4">Books & Literature</option>
                <option value="5">Business & Industrial</option>
                <option value="6">Computers & Electronics</option>
                <option value="7">Finance</option>
                <option value="8">Food & Drink</option>
                <option value="9">Games</option>
                <option value="10">Health</option>
                <option value="11">Hobbies & Leisure</option>
                <option value="12">Home & Garden</option>
              </select>
            </div>
          </div>
        </div>
        {/* section 3 */}
        <div className="container section-3-main-flex ">
          <div className="section-3-child-flex-1 container">
            <h6 className="text-uppercase">benefits</h6>
            <p>
              Only the highest
              <br /> paying ads go live
            </p>
            <p className="fw-light" style={{ fontFamily: "Roboto" }}>
              Boost your revenue by tapping into the largest
              <br /> network of online advertisers who bid for your ad <br />
              space.
            </p>
          </div>
          <div className="section-3-child-flex-2">
            <img src="img/img_1.png" alt="" />
          </div>
        </div>
        {/* section 4 */}
        <div>
          <div className="container section-4-main-flex container ">
            <div className="section-4-child-flex-1">
              <img src="img/img_5.png" alt="" />
            </div>
            <div className="section-4-child-flex-2 container">
              <h6 className="text-uppercase">benefits</h6>
              <p>
                The right ads for your
                <br /> audience
              </p>
              <p className="fw-light" style={{ fontFamily: "Roboto" }}>
                Ads are screened to ensure they’re high quality and
                <br /> relevant to your content or audience, even when <br />
                viewed on smartphones and tablets. The result?
                <br /> You can make more money online.
              </p>
            </div>
          </div>
        </div>
        {/* section 5 */}
        <div className="container section-5-main-flex  ">
          <div className="section-5-child-flex-1 container">
            <h6 className="text-uppercase">benefits</h6>
            <p className="fs-2 mx-3">You’re in control</p>
            <p className="fw-light mx-3" style={{ fontFamily: "Roboto" }}>
              Block ads you don't like, customize where ads
              <br /> appear, and choose which types fit your site best.
            </p>
          </div>
          <div className="section-5-child-flex-2 d-flex justify-content-even align-items-center">
            <img src="img/img_6.png" width={400} alt="" />
          </div>
        </div>
      </div>
      {/* section 6 */}
      <div
        className="container-fluid position-relative bg-body shadow-lg mb-5"
        style={{ marginTop: "100px" }}
      >
        <div className="section-6-img-container"></div>
        <div className="section-6-img-overlay bg-body shadow-lg mb-5">
          <div className="position-relative p-5">
            <h3 className="rounded-circle bg-primary fs-3 --rcircle p-3 text-center text-light position-absolute mt-5 top-0 start-50 translate-middle">
              “
            </h3>
            <div>
            <p className="text-center pt-5 ">
              All you have to do is drop the little AdSense code <br />
              into your website and it immediately starts
              <br /> working
            </p>
            </div>
            <div className="container">
            <p className="text-center fst-italic">
              - Jack Herrick, WikiHow Founder and CEO
            </p>
            </div>
            <div className="container d-flex t w-25 align-items-center mt-5 ">
            <FaYoutube className="text-primary fs-3  me-2" />
            <p className="text-center fst-italic" >
              
              <Link to="#" className="text-decoration-none">
              View success story
              </Link>
            </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br /> <br /> <br /> <br /> <br />
      {/* section 7 */}
      <section className="p-5" style={{ backgroundColor: "rgb(241,243,244)" }}>
        <h2 className="container text-center fw-normal">Get started</h2>
        <p className="container text-center">
          You just need 3 things to get started with AdSense
        </p>
        <div className="ads-parent-container container mt-5">
          <div className="ads-child-container1 container">
            <div>
              <div className="img-container">
                <img
                  src="img/get-started-1.svg"
                  alt="visa-card-icon"
                  className="bg-body shadow-lg rounded-circle"
                  width={150}
                  style={{ marginLeft: "0px" }}
                />
              </div>
              <h2 className="bg-primary   rounded-circle-w p-3 text-light text-center mt-5">
                1
              </h2>
              <h6 className="text-start mb-3 mt-5">A Google Account</h6>
              <p className="text-start fw-light ">
                If you’re using Gmail or any other Google service, you already
                have one. If not, just click to sign up and we’ll help you
                create your new account. It gets you into AdSense and everything
                Google.
              </p>
            </div>
          </div>
          <div className="ads-child-container2 container">
            <div>
              <div className="img-container">
                <img
                  src="img/get-started-2.svg"
                  style={{ marginLeft: "0px" }}
                  width={150}
                  className="bg-body shadow-lg rounded-circle"
                  alt="visa-card-icon"
                />
              </div>
              <h2 className="bg-primary rounded-circle-w p-3 text-light text-center mt-5">
                2
              </h2>
              <h6 className="text-start mb-3 mt-5">
                Phone number and postal address
              </h6>
              <p className="text-start">
                Your phone number and the mailing address associated with your
                bank account so you can get paid.
              </p>
            </div>
          </div>
          <div className="ads-child-container3 container">
            <div>
              <div className="img-container">
                <img
                  src="img/get-started-3.svg"
                  width={150}
                  className="bg-body shadow-lg rounded-circle"
                  style={{ marginLeft: "0px" }}
                  alt="visa-card-icon"
                />
              </div>
              <h2 className="bg-primary rounded-circle-w p-3 text-light text-center mt-5">
                3
              </h2>
              <h6 className="text-start mb-3 mt-5">
                Connect your site to AdSense
              </h6>
              <p className="text-start">
                Add one piece of code to your site and Google will take care of
                the rest, allowing you to grow your business.
              </p>
            </div>
          </div>
        </div>

        <div className="container text-center mt-5">
          <button className="btn btn-primary ">Get Started</button>
          <p className="fw-light mt-4">
            Contact an AdSense expert for help that's specific to you.
          </p>
          <p className="fw-light mt-4">
            <Link to="#" className="text-decoration-none">
              Contact an expert
            </Link>{" "}
            <FaArrowCircleRight className="text-primary" />
          </p>
        </div>
        <div className="d-flex align-item-center mx-5">
          <p className="fw-light m-3">Follow us </p>
          <FaYoutube className="m-3" style={{ cursor: "pointer" }} />
          <FaTwitter className="m-3" style={{ cursor: "pointer" }} />
          <FaFacebook className="m-3" style={{ cursor: "pointer" }} />
        </div>
        <hr />
        {/*footer section */}

        <div class="container-fluid my-5">
          <footer style={{ backgroundColor: "rgb(241,243,244)" }}>
            <div class="container p-4">
              <div class="row">
                <div class="col-lg-3 col-md-6 mb-4">
                  <h5 class="mb-3 fw-normal" style={{ letterSpacing: "2px" }}>
                    Learning & support
                  </h5>
                  <ul class="list-unstyled mb-0">
                    <li class="mb-1">
                      <a
                        href="#!"
                        style={{ color: "#202124" }}
                        className="text-decoration-none fw-light"
                      >
                        Help Center
                      </a>
                    </li>
                    <li class="mb-1">
                      <a
                        href="#!"
                        style={{ color: "#202124" }}
                        className="text-decoration-none fw-light"
                      >
                        AdSense Help Forum
                      </a>
                    </li>
                    <li class="mb-1">
                      <a
                        href="#!"
                        style={{ color: "#202124" }}
                        className="text-decoration-none fw-light"
                      >
                        Certified Publishing Partners
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-3 col-md-6 mb-4">
                  <h5 class="mb-3 fw-normal" style={{ letterSpacing: "2px" }}>
                    Developers
                  </h5>
                  <ul class="list-unstyled mb-0">
                    <li class="mb-1">
                      <a
                        href="#!"
                        style={{ color: "#202124" }}
                        className="text-decoration-none fw-light"
                      >
                        Google Developers site
                      </a>
                    </li>
                    <li class="mb-1">
                      <a
                        href="#!"
                        style={{ color: "#202124" }}
                        className="text-decoration-none fw-light"
                      >
                        AdSense Management API
                      </a>
                    </li>
                    <li class="mb-1">
                      <a
                        href="#!"
                        style={{ color: "#202124" }}
                        className="text-decoration-none fw-light"
                      >
                        AdSense Custom Search Ads
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-3 col-md-6 mb-4">
                  <h5 class="mb-3 fw-normal" style={{ letterSpacing: "2px" }}>
                    Related products
                  </h5>
                  <ul class="list-unstyled mb-0">
                    <li class="mb-1">
                      <a
                        href="#!"
                        style={{ color: "#202124" }}
                        className="text-decoration-none fw-light"
                      >
                        Google Ads
                      </a>
                    </li>
                    <li class="mb-1">
                      <a
                        href="#!"
                        style={{ color: "#202124" }}
                        className="text-decoration-none fw-light"
                      >
                        Google Ad Manager
                      </a>
                    </li>
                    <li class="mb-1">
                      <a
                        href="#!"
                        style={{ color: "#202124" }}
                        className="text-decoration-none fw-light"
                      >
                        Google AdMob
                      </a>
                    </li>
                    <li>
                      <a
                        href="#!"
                        style={{ color: "#202124" }}
                        className="text-decoration-none fw-light"
                      >
                        AdSense for Shopping
                      </a>
                    </li>
                    <li>
                      <a
                        href="#!"
                        style={{ color: "#202124" }}
                        className="text-decoration-none fw-light"
                      >
                        Google Site Kit
                      </a>
                    </li>
                    <li>
                      <a
                        href="#!"
                        style={{ color: "#202124" }}
                        className="text-decoration-none fw-light"
                      >
                        Blogger
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-3 col-md-6 mb-4">
                  <h5 class="mb-3 fw-normal" style={{ letterSpacing: "0px" }}>
                    More solutions from Google
                  </h5>
                  <ul class="list-unstyled mb-0">
                    <li class="mb-1">
                      <a
                        href="#!"
                        style={{ color: "#202124" }}
                        className="text-decoration-none fw-light"
                      >
                        Business solutions
                      </a>
                    </li>
                    <li class="mb-1">
                      <a
                        href="#!"
                        style={{ color: "#202124" }}
                        className="text-decoration-none fw-light"
                      >
                        Google Workspace
                      </a>
                    </li>
                    <li class="mb-1">
                      <a
                        href="#!"
                        style={{ color: "#202124" }}
                        className="text-decoration-none fw-light"
                      >
                        Google Analytics
                      </a>
                    </li>
                    <li>
                      <a
                        href="#!"
                        style={{ color: "#202124" }}
                        className="text-decoration-none fw-light"
                      >
                        Think with Google
                      </a>
                    </li>
                    <li>
                      <a
                        href="#!"
                        style={{ color: "#202124" }}
                        className="text-decoration-none fw-light"
                      >
                        Google Domains
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
          </footer>
        </div>
        {/* last section */}
        <div className="container footer-last-section">
       
        <div className="d-flex align-item-center">
        <h4 className="m-3" style={{ color: "#202124" }}>Google</h4>
          <a href="!#" className="text-decoration-none m-3"  style={{ color: "#202124" }}>Privacy</a>
          <a href="!#" className="text-decoration-none m-3"  style={{ color: "#202124" }}>Terms</a>
          <a href="!#" className="text-decoration-none m-3"  style={{ color: "#202124" }}>About Google</a>
          <a href="!#" className="text-decoration-none m-3"  style={{ color: "#202124" }}>Google products</a>
        </div>
        <div>
          <a href="!#" className="text-decoration-none m-3"  style={{ color: "#202124" }}>Help</a>
          <a href="!#" className="text-decoration-none m-3"  style={{ color: "#202124" }}>United States - English</a>
  
        </div>
        </div>
      </section>
    </section>
  );
}

export default AdsContent;
