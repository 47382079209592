import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";

const Hero = () => {
  return (
    <div
      className={`relative min-h-[70vh] 800px:min-h-[100vh] w-full bg-no-repeat ${styles.noramlFlex} hero-img`}
      style={{
        backgroundImage:
          "url(https://images.unsplash.com/photo-1495365200479-c4ed1d35e1aa?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
        backgroundPosition: 'center', // Centers the background image
        backgroundSize: 'cover', // Makes the background cover the available space
        textShadow:"1px 1px 1px black"
      }}
    >
      <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
        <h1
          className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#F8F8F8] font-[600] capitalize font-extrabold`}
        >
          Hospitality,Ratings <br /> Reviews, Commissions
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#F8F8F8] font-normal" style={{
       
        
      }}>
          A unique platform where clients review and rate hotels, transforming
          their opinions <br />into earnings through commissions. It fosters a
          community-driven approach to<br /> hospitality, empowering users to share
          authentic experiences and make informed <br />decisions while financially
          benefiting from their contributions.
        </p>

        <Link to="/popular-hotels" className="inline-block">
          <div className={`${styles.button} mt-5 border-3 border-sky-500`}>
            <span className="text-[#fff] font-[Poppins] text-[18px]">
              Rate Now
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Hero;
