import React, { useState, useEffect } from "react";
import axios from "axios";
import { server } from "../../server"; // Ensure this points to your backend server
import { toast } from "react-toastify";

function AdminUsers() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);
  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${server}/user/admin-get-withdrawal-methods`
      ); // Use GET and updated endpoint
      // console.log(response.data.data);
      setUsers(response.data.data);
    } catch (error) {
      console.error("Error fetching user details:", error);
      toast.error("Error fetching user details");
    }
  };

  const resetTotalRatings = async (userId) => {
    try {
      await axios.post(`${server}/user/reset-total-ratings`, { userId });
      toast.success("Total ratings reset successfully");
      fetchUsers(); // Re-fetch users to update the UI
    } catch (error) {
      console.error("Error resetting total ratings:", error);
      toast.error("Error resetting total ratings");
    }
  };

  const addToBalance = async (userId) => {
    const amount = prompt("Enter the amount to add:"); // Get the amount from the user
    if (!amount || isNaN(amount) || amount <= 0) {
      toast.error("Please enter a valid amount.");
      return;
    }

    try {
      await axios.post(`${server}/user/add-to-balance`, {
        userId,
        amount: Number(amount),
      });
      toast.success("Balance updated successfully");
      fetchUsers(); // Re-fetch users to update the UI
    } catch (error) {
      console.error("Error updating balance:", error);
      toast.error("Error updating balance");
    }
  };

  if (users.length === 0) return <div>Loading...</div>; // Added a loading state

  return (
    <div className="max-w-6xl mx-auto py-8">
      <h2 className="text-3xl font-semibold text-center mb-10">
        Users' Withdrawal Methods
      </h2>
      {users.map((user) => (
        <div key={user._id} className="mb-12">
          <h3 className="text-xl font-medium mb-4">{user.name}</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Method Type
                  </th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Details
                  </th>
                  
                </tr>
              </thead>
              <tbody className="bg-white">
                {user.withdrawalMethods.map((method, index) => (
                  <tr
                    key={index}
                    className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}
                  >
                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                      {method.methodType}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                      {Object.entries(method.details).map(([key, value]) => (
                        <div key={key}>{`${key}: ${value}`}</div>
                      ))}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                      {method.country}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <button
              onClick={() => resetTotalRatings(user._id)}
              className="px-4 py-2 mt-3 bg-red-700 text-white rounded hover:bg-red-500 transition duration-150"
            >
              Reset Total Ratings
            </button>
            <button
              onClick={() => addToBalance(user._id)}
              className="px-4 py-2 m-3 bg-green-700 text-white rounded hover:bg-green-700 transition duration-150"
            >
              Add Balance
            </button> */}
          </div>
        </div>
      ))}
    </div>
  );
}

export default AdminUsers;
