import React, { createContext, useState } from 'react';

// Create the context
export const AuthContext = createContext();

// Provider component that wraps your app components
export function AuthProvider({ children }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    // Example function to simulate login/logout
    const logIn = () => setIsLoggedIn(true);
    const logOut = () => setIsLoggedIn(false);

    // Value provided to the context consumers
    const value = {
        isLoggedIn,
        logIn,
        logOut
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}