import React from 'react'
import NavigationBar from './Navbar'
import Hero from './Hero'
import AdsContent from './AdsContent'

function MainHomepage() {
  return (
    <div>
        <NavigationBar />
        <Hero />
        <AdsContent />
    </div>
  )
}

export default MainHomepage