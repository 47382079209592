import React, { Component } from 'react';
import ProductComponent from './ProductComponent'; 

class ProductList extends Component {
  state = {
    products: []
  };

  handleProductUpdate = (updatedProduct) => {
    this.setState(prevState => ({
      products: prevState.products.map(product => 
        product._id === updatedProduct._id ? updatedProduct : product
      )
    }));
  };

  render() {
    return (
      <div>
        {this.state.products.map(product => (
          <ProductComponent 
            key={product._id} 
            product={product} 
            onProductUpdate={this.handleProductUpdate} 
          />
        ))}
      </div>
    );
  }
}

export default ProductList;