// import React, { useState } from 'react';
// import axios from 'axios';
// import Select from 'react-select';
// import countryList from 'react-select-country-list';
// import { server } from "../../server";
// import { toast } from "react-toastify";

// function AddWithdrawalMethod({userId}) {
//   const [methodType, setMethodType] = useState('');
//   const [country, setCountry] = useState('');
//   const [options, setOptions] = useState(countryList().getData());
//   const [details, setDetails] = useState({
//     address: '',
//     accountHolderName: '',
//     contactNumber: '',
//     wiseEmail: '',
//     fullName: '',
//     bankName: '',
//     accountId: '',
//     sortCode: '',
//     email: '',
//   });

//   const handleDetailChange = (e) => {
//     const { name, value } = e.target;
//     setDetails(prevDetails => ({
//       ...prevDetails,
//       [name]: value,
//     }));
//   };

//   const handleCountryChange = (value) => {
//     setCountry(value);
//     setDetails({ ...details, country: value.label });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//     //    const userId = user._id;
//       await axios.post( `${server}/user/add-withdrawal-method`, {
//         userId,
//         methodType,
//         details: Object.keys(details)
//           .filter(key => details[key] !== '')
//           .reduce((obj, key) => {
//             obj[key] = details[key];
//             return obj;
//           }, {}),
//           country: country.label,
//       });
//       toast.success('Withdrawal method added successfully')
//       window.location.reload(true);

//     } catch (error) {
//       // console.log(error);
//       toast.error('Failed to add withdrawal method');
//     }
//   };

//   return (
//     <div className="max-w-md
//      mx-auto my-10 bg-white p-8 border border-gray-300 rounded-lg shadow-lg">
//       <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">Add Withdrawal Method</h2>
//       <form onSubmit={handleSubmit}>

//     {/* Country Selection */}
//     <div className="mb-4">
//         <label className="block text-gray-700 text-sm font-bold mb-2">Country</label>
//         <Select
//           options={options}
//           required
//           value={country}
//           onChange={handleCountryChange}
//           className="text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//         />
//       </div>


//         <div className="mb-4">
//           <label className="block text-gray-700 text-sm font-bold mb-2">Method Type</label>
//           <select className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                   value={methodType} onChange={(e) => setMethodType(e.target.value)} required>
//             <option value="">Select Method</option>
//             <option value="USDT_ERC20">USDT ERC20</option>
//             <option value="USDT_TRC20">USDT TRC20</option>
//             <option value="WISE">WISE</option>
//             <option value="Instant_Transfer">Instant Transfer</option>
//             <option value="Skrill_Account">Skrill Account</option>
//           </select>
//         </div>
        
//         {methodType === 'USDT_ERC20' || methodType === 'USDT_TRC20' ? (
//           <div className="mb-4">
//             <label className="block text-gray-700 text-sm font-bold mb-2">Address</label>
//             <input
//               type="text"
//               name="address"
//               value={details.address}
//               onChange={handleDetailChange}
//               required
//               className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//             />
//           </div>
//         ) : null}

//         {methodType === 'WISE' ? (
//           <>
//             <div className="mb-4">
//               <label className="block text-gray-700 text-sm font-bold mb-2">Account Holder Name</label>
//               <input
//                 type="text"
//                 name="accountHolderName"
//                 value={details.accountHolderName}
//                 onChange={handleDetailChange}
//                 required
//                 className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//               />
//             </div>
//             <div className="mb-4">
//               <label className="block text-gray-700 text-sm font-bold mb-2">Contact Number</label>
//               <input
//                 type="text"
//                 name="contactNumber"
//                 value={details.contactNumber}
//                 onChange={handleDetailChange}
//                 required
//                 className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//               />
//             </div>
//             <div className="mb-4">
//               <label className="block text-gray-700 text-sm font-bold mb-2">Wise Email</label>
//               <input
//                 type="email"
//                 name="wiseEmail"
//                 value={details.wiseEmail}
//                 onChange={handleDetailChange}
//                 required
//                 className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//               />
//             </div>
//           </>
//         ) : null}

//         {/* Fields for Instant Transfer */}
//         {methodType === 'Instant_Transfer' && (
//           <>
//             <div className="mb-4">
//               <label className="block text-gray-700 text-sm font-bold mb-2">Full Name</label>
//               <input
//                 type="text"
//                 name="fullName"
//                 value={details.fullName}
//                 onChange={handleDetailChange}
//                 required
//                 className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//               />
//             </div>
//             <div className="mb-4">
//               <label className="block text-gray-700 text-sm font-bold mb-2">Bank Name</label>
//               <input
//                 type="text"
//                 name="bankName"
//                 value={details.bankName}
//                 onChange={handleDetailChange}
//                 required
//                 className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//               />
//             </div>
//             <div className="mb-4">
//               <label className="block text-gray-700 text-sm font-bold mb-2">Account ID</label>
//               <input
//                 type="text"
//                 name="accountId"
//                 value={details.accountId}
//                 onChange={handleDetailChange}
//                 required
//                 className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//               />
//             </div>
//             <div className="mb-4">
//               <label className="block text-gray-700 text-sm font-bold mb-2">Sort Code</label>
//               <input
//                 type="text"
//                 name="sortCode"
//                 value={details.sortCode}
//                 onChange={handleDetailChange}
//                 required
//                 className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//               />
//             </div>
//           </>
//         )}

//         {methodType === 'Skrill_Account' ? (
//           <div className="mb-4">
//             <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
//             <input
//               type="email"
//               name="email"
//               value={details.email}
//               onChange={handleDetailChange}
//               required
//               className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//             />
//           </div>
//         ) : null}

//         <div className="flex items-center justify-between">
//           <button className="bg-green-700 hover:bg-green-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
//             Add Method
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// }

// export default AddWithdrawalMethod;

import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { server } from "../../server";
import { toast } from "react-toastify";

function AddWithdrawalMethod({ userId }) {
  const [methodType, setMethodType] = useState('');
  const [country, setCountry] = useState(null); // Ensure null is the initial state for react-select
  const [options, setOptions] = useState(countryList().getData());
  const [details, setDetails] = useState({
    fullName: '',
    bankName: '',
    accountId: '',
    sortCode: '',
    email: '',
    routineNumber: '',
    ibanNumber: '',
    paypal: '',
    wiseTag: '',
    revTag: '',
    address: '',
    europe: '',
    uk: '',
    usa: ''
  });

  const handleDetailChange = (e) => {
    const { name, value } = e.target;
    setDetails(prevDetails => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleCountryChange = (value) => {
    setCountry(value);
    // Reset details when country changes to ensure relevant fields are filled out
    setDetails({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${server}/user/add-withdrawal-method`, {
        userId,
        methodType,
        details: { ...details, country: country.label },
      });
      toast.success('Withdrawal method added successfully');
      window.location.reload(true);
    } catch (error) {
      toast.error('Failed to add withdrawal method');
    }
  };

  // Fields based on country and method
  const showField = (fieldName) => {
    const countryFieldMap = {
      'United Kingdom': ['fullName', 'bankName', 'accountId', 'sortCode', 'address'],
      'United States': ['fullName', 'bankName', 'accountId', 'routineNumber', 'address'],
      'Europe': ['fullName', 'bankName', 'accountId', 'ibanNumber', 'address'], // Adjust based on actual value
    };
    const methodFieldMap = {
      'USDT_ERC20': 'address',
      'USDT_TRC20': 'address',
      'Paypal': 'email',
      'WISE': ['email', 'wiseTag'],
      'Revolut': ['fullName', 'accountId', 'revTag'],
      'Europe': ['fullName', 'bankName', 'accountId', 'ibanNumber', 'address'],
      'UK': '',
      'USA': '',
    };

    const countryFields = country ? countryFieldMap[country.label] || [] : [];
    const methodFields = methodFieldMap[methodType] || [];

    return countryFields.includes(fieldName) || methodFields.includes(fieldName);
  };

  return (
    <div className="max-w-md mx-auto my-10 bg-white p-8 border border-gray-300 rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">Add Withdrawal Method</h2>
      <form onSubmit={handleSubmit}>
        {/* Country Selection */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Country</label>
          <Select
          required
            options={options}
            value={country}
            onChange={handleCountryChange}
            className="text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        {/* Method Type Selection */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Method Type</label>
          <select
            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={methodType}
            onChange={(e) => setMethodType(e.target.value)}
            required
          >
            <option value="">Select Method</option>
            <option value="USDT_ERC20">USDT ERC20</option>
            <option value="USDT_TRC20">USDT TRC20</option>
            <option value="Paypal">PayPal</option>
            <option value="WISE">WISE</option>
            <option value="Revolut">Revolut</option>
            <option value="Europe">Europe(Only)</option>
            <option value="UK">UK(Only)</option>
            <option value="USA">USA(Only)</option>
          </select>
        </div>

        {/* Dynamic Fields */}
        {showField('fullName') && (
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Full Name</label>
            <input
              type="text"
              name="fullName"
              value={details.fullName}
              onChange={handleDetailChange}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        )}

        {showField('bankName') && (
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Bank Name</label>
            <input
              type="text"
              name="bankName"
              value={details.bankName}
              onChange={handleDetailChange}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        )}

        {showField('accountId') && (
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Account Number</label>
            <input
              type="text"
              name="accountId"
              value={details.accountId}
              onChange={handleDetailChange}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        )}

        {/* Additional fields for sortCode, routineNumber, ibanNumber, email, wiseTag, revTag, address follow the same pattern */}

        {showField('sortCode') && (
  <div className="mb-4">
    <label className="block text-gray-700 text-sm font-bold mb-2">Sort Code</label>
    <input
      type="text"
      name="sortCode"
      value={details.sortCode}
      onChange={handleDetailChange}
      required={showField('sortCode')}
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    />
  </div>
)}

{showField('routineNumber') && (
  <div className="mb-4">
    <label className="block text-gray-700 text-sm font-bold mb-2">Routing Number</label>
    <input
      type="text"
      name="routineNumber"
      value={details.routineNumber}
      onChange={handleDetailChange}
      required={showField('routineNumber')}
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    />
  </div>
)}

{showField('ibanNumber') && (
  <div className="mb-4">
    <label className="block text-gray-700 text-sm font-bold mb-2">IBAN Number</label>
    <input
      type="text"
      name="ibanNumber"
      value={details.ibanNumber}
      onChange={handleDetailChange}
      required={showField('ibanNumber')}
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    />
  </div>
)}

{showField('email') && (
  <div className="mb-4">
    <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
    <input
      type="email"
      name="email"
      value={details.email}
      onChange={handleDetailChange}
      required={showField('email')}
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    />
  </div>
)}




{showField('paypal') && (
  <div className="mb-4">
    <label className="block text-gray-700 text-sm font-bold mb-2">PayPal</label>
    <input
      type="text"
      name="paypal"
      value={details.wiseTag}
      onChange={handleDetailChange}
      required={showField('paypal')}
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    />
  </div>
)}

{showField('revTag') && (
  <div className="mb-4">
    <label className="block text-gray-700 text-sm font-bold mb-2">Revolut Tag</label>
    <input
      type="text"
      name="revTag"
      value={details.revTag}
      onChange={handleDetailChange}
      required={showField('revTag')}
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    />
  </div>
)}

{showField('address') && (
  <div className="mb-4">
    <label className="block text-gray-700 text-sm font-bold mb-2">Address</label>
    <input
      type="text"
      name="address"
      value={details.address}
      onChange={handleDetailChange}
      required={showField('address')}
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    />
  </div>
)} 
{showField('europe') && (
  <div className="mb-4">
    <label className="block text-gray-700 text-sm font-bold mb-2">Europe(only)</label>
    <input
      type="text"
      name="europe"
      value={details.europe}
      onChange={handleDetailChange}
      required={showField('europe')}
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    />
  </div>
)} 
{showField('uk') && (
  <div className="mb-4">
    <label className="block text-gray-700 text-sm font-bold mb-2">UK(only)</label>
    <input
      type="text"
      name="uk"
      value={details.europe}
      onChange={handleDetailChange}
      required={showField('uk')}
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    />
  </div>
)} 
{showField('usa') && (
  <div className="mb-4">
    <label className="block text-gray-700 text-sm font-bold mb-2">USA(only)</label>
    <input
      type="text"
      name="usa"
      value={details.europe}
      onChange={handleDetailChange}
      required={showField('usa')}
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    />
  </div>
)} 


        <div className="flex items-center justify-between">
          <button
            className="bg-green-700 hover:bg-green-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Add Method
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddWithdrawalMethod;