import React from "react";
import { Link } from "react-router-dom";

function Deposit() {
  return (
    <divv>
      <h3 className="text-center mb-5">Contact Customer Service</h3>
      <div class="flex text-center relative profile-block">
        <div class="flex-2 w-64  bg-body m-2 p-4">
          <Link to="https://wa.me/message/JSE5PL47G6JQH1">
            <div className="flex  align-item-center">
              <img
                src="https://cdn-icons-png.flaticon.com/128/733/733585.png"
                alt=""
                width={50}
              />
              <h3 className="p-3">WhatsApp</h3>
            </div>
          </Link>
        </div>
        <div class="flex-2 w-64  bg-body m-2 p-4">
         <Link to = "https://t.me/Customer_service_Clara">
         <div className="flex  align-item-center">
            <img
              src="https://cdn-icons-png.flaticon.com/128/216/216576.png"
              alt=""
              width={50}
            />
            <h3 className="p-3">Telegram</h3>
          </div>
         </Link>
        </div>
      </div>
    </divv>
  );
}

export default Deposit;
