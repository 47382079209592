import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { server } from "../../server";
import { toast } from "react-toastify";
import axios from "axios";

function AllUsersRatings() {
  const [orders, setOrders] = useState([]);
  const { user } = useSelector((state) => state.user);
  const [responseMessage, setResponseMessage] = useState("");
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [filteredOrders, setFilteredOrders] = useState([]); // State for filtered orders

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchUsers();
    fetchOrders();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${server}/user/admin-get-withdrawal-methods`
      );
      setUsers(response.data.data);
    } catch (error) {
      console.error("Error fetching user details:", error);
      toast.error("Error fetching user details");
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${server}/product/client-orders`);
      if (response.status === 200) {
        setOrders(response.data);
      } else {
        throw new Error("Failed to fetch orders");
      }
    } catch (error) {
      toast.error("Failed to fetch orders");
    }
  };

  const updateProductPrice = async (productId, userId) => {
    const newPrice = prompt("Enter the new price:");
    if (!newPrice || isNaN(newPrice) || newPrice <= 0) {
      toast.error("Please enter a valid price.");
      return;
    }

    try {
      const response = await axios.post(`${server}/product/update-price`, {
        productId,
        newPrice: Number(newPrice),
        userId
      });
      if (response.data.success) {
        toast.success("Hotel price updated successfully");
        fetchOrders();
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error("Error updating hotel price:", error);
      toast.error("Error updating hotel price");
    }
  };

  const resetTotalRatings = async (userId) => {
    try {
      await axios.post(`${server}/user/reset-total-ratings`, { userId });
      toast.success("Total ratings reset successfully");
      fetchUsers();
    } catch (error) {
      console.error("Error resetting total ratings:", error);
      toast.error("Error resetting total ratings");
    }
  };

  const addToBalance = async (userId) => {
    const amount = prompt("Enter the amount to add:");
    if (!amount || isNaN(amount) || amount <= 0) {
      toast.error("Please enter a valid amount.");
      return;
    }

    try {
      await axios.post(`${server}/user/add-to-balance`, {
        userId,
        amount: Number(amount),
      });
      toast.success("Balance updated successfully");
      fetchUsers();
    } catch (error) {
      console.error("Error updating balance:", error);
      toast.error("Error updating balance");
    }
  };

  const addAmountToProcessing = async (userId) => {
    const amount = prompt("Enter the amount to add to processing:");
    if (!amount || isNaN(amount) || amount <= 0) {
      toast.error("Please enter a valid amount.");
      return;
    }

    try {
      await axios.post(`${server}/user/add-to-processing`, {
        userId,
        amount: Number(amount),
      });
      toast.success("Amount added to processing successfully");
      fetchOrders();
    } catch (error) {
      console.error("Error adding amount to processing:", error);
      toast.error("Error adding amount to processing");
    }
  };

  const deleteRating = async (ratingId) => {
    if (window.confirm("Are you sure you want to delete this rating?")) {
      try {
        await axios.delete(`${server}/product/delete/${ratingId}`);
        toast.success("Rating deleted successfully");
        
        fetchOrders();
      } catch (error) {
        console.error("Error deleting rating:", error);
        toast.error("Error deleting rating");
      }
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    const filtered = orders.filter(order =>
      order.user && order.user.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredOrders(filtered);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOrders = filteredOrders.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

  return (
    <div className="max-w-4xl mx-auto py-8">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">
        All Users Ratings
      </h1>
      <div className="flex mb-4">
        <input
          type="text"
          placeholder="Search by name"
          value={searchQuery}
          onChange={handleSearchChange}
          onKeyPress={handleKeyPress}
          className="p-2 border border-gray-300 rounded w-full"
        />
        <button
          onClick={handleSearch}
          className="ml-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Search
        </button>
      </div>
      {currentOrders.length > 0 ? (
        currentOrders.map((order) => (
          <div
            key={order._id}
            className="bg-white shadow-lg rounded-lg overflow-hidden mb-8"
          >
            <div className="p-6 d-flex">
              <h2 className="text-xl mb-4 font-semibold text-gray-700">
                Hotel ID: {order._id}
                <br />
                Name: {order.user.name}<br />
              </h2>
              {order.cart.map((item) => (
                <div
                  key={item.productId._id}
                  className="mb-4 p-4 border-b last:border-b-0"
                >
                  <div className="flex justify-between items-center">
                    <div className="text-lg font-semibold text-gray-900">
                      {item.productId.name}
                    </div>
                  </div>
                  <div className="text-md text-gray-700 mt-1">
                    Rating ID: {item._id}
                  </div>
                  <div className="text-md text-gray-700 mt-1">
                    Rating time: {item.productId.createdAt}
                  </div>
                  <div className="text-md text-gray-700 mt-1">
                    Price: ${item.productId.discountPrice}
                  </div>
                  <button
                    onClick={() => updateProductPrice(item.productId._id, order.user._id)}
                    className="px-4 py-2 mt-3 bg-yellow-600 text-white rounded hover:bg-yellow-500 transition duration-150"
                  >
                    Update Hotel Price
                  </button>
                  
                 
                  
                </div>
              ))}

                 
              
              <div className="space-y-4">

              <button
                    onClick={() => addAmountToProcessing(order.user._id)}
                    className="px-4 py-2 mt-3 ml-2 bg-green-600 text-white rounded hover:bg-green-500 transition duration-150"
                  >
                    Add Amount to Processing
                  </button>

                 <button
                    onClick={() => deleteRating(order._id)}
                    className="px-4 py-2 mt-3 ml-2 bg-red-600 text-white rounded hover:bg-red-500 transition duration-150"
                  >
                    Delete Rating
                  </button>

                <button
                  onClick={() => resetTotalRatings(order.user._id)}
                  className="px-4 py-2 mt-3 bg-red-700 text-white rounded hover:bg-red-500 transition duration-150"
                >
                  Reset Total Ratings
                </button>
                <button
                  onClick={() => addToBalance(order.user._id)}
                  className="px-4 py-2 m-3 bg-green-700 text-white rounded hover:bg-green-700 transition duration-150"
                >
                  Add Balance
                </button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center text-gray-600 text-xl">
          No ratings found.
        </div>
      )}
      {/* Pagination Controls */}
      {filteredOrders.length > itemsPerPage && (
        <div className="flex justify-center mt-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-4 py-2 mx-1 bg-gray-300 text-gray-800 rounded ${currentPage === 1 ? "cursor-not-allowed" : "hover:bg-gray-400"}`}
          >
            Previous
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={`px-4 py-2 mx-1 ${currentPage === index + 1 ? "bg-blue-500 text-white" : "bg-gray-300 text-gray-800 hover:bg-gray-400"} rounded`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`px-4 py-2 mx-1 bg-gray-300 text-gray-800 rounded ${currentPage === totalPages ? "cursor-not-allowed" : "hover:bg-gray-400"}`}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
}

export default AllUsersRatings;
