import React from 'react'
import Container from 'react-bootstrap/Container';
import {Nav} from 'react-bootstrap';
import {Navbar} from 'react-bootstrap';
import { Link } from 'react-router-dom';



function NavigationBar() {
  return (
    <div>
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary ">
      <Container fluid>
        <Navbar.Brand href="#home"><img src='img/ads-logo.png' alt='logo'/></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
           
            
          </Nav>
          <Nav className='d-flex align-items-center justify-content-even'>
            <Nav.Link href="/login" className='text-primary'>Sign in</Nav.Link>
            <Link to="/sign-up">
             <button className='btn btn-primary'>Get started</button>
            </Link>
           
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  );
}

export default NavigationBar;