import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { server } from '../../server';

const ProductsDashboard = () => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        // Fetch products from the backend
        const fetchProducts = async () => {
            const response = await axios.get(`${server}/product/get-all-products`); 
            // console.log(response.data.products);
            setProducts(response.data.products);
        };

        fetchProducts();
    }, []);

    const updateDiscountPrice = async (productId, newPrice) => {
        await axios.patch(`${server}/product/products/${productId}`, { discountPrice: newPrice }); // Update with your actual API endpoint
        // Re-fetch products to reflect the updated price
        const response = await axios.get(`${server}/product/get-all-products`); // Update with your actual API endpoint
        setProducts(response.data.products);
        window.location.reload('/dashboard-change-price');
    };

    return (
        <div className="p-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {Array.isArray(products) && products.map((product) => (
                    <div key={product._id} className="p-4 border rounded shadow">
                        <h2 className="text-lg font-bold">{product.name}</h2>
                        
                        <div className="flex justify-between items-center mt-2">
                            <span>Hotel Price: ${product.discountPrice}</span>
                            
                        </div>
                        <input
                            type="number"
                            className="mt-2 p-2 border rounded"
                            placeholder="New hotel price"
                            onChange={(e) => product.newPrice = e.target.value}
                        />
                        <button
                            className="mt-2 px-4 py-2 bg-green-700 text-white rounded hover:bg-green-500 transition"
                            onClick={() => updateDiscountPrice(product._id, product.newPrice)}
                        >
                            Update Price
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductsDashboard;