import React from 'react'
import { Link } from 'react-router-dom'

function Hero() {
  return (
    <div className="hero-container">
      <div className="hero-text">
        <h1 className='we-value-your'>We value your </h1>
        <h1 className='content'>content</h1>
        <p className='mt-2'>Creating content takes time, making it profitable shouldn't</p>
        <Link to="/sign-up">
        <button className='btn btn-primary mt-3'>Get Started</button>

        </Link>
      </div>
    </div>
  )
}

export default Hero
