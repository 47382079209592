import React from "react";
import { AiOutlineLogin, AiOutlineMessage } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { HiOutlineReceiptRefund, HiOutlineShoppingBag } from "react-icons/hi";
import {
  MdOutlineAdminPanelSettings,
  MdOutlineTrackChanges,
} from "react-icons/md";
import { TbAddressBook } from "react-icons/tb";
import { RxPerson } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const ProfileSidebar = ({ setActive, active }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  const logoutHandler = () => {
    toast.success("Logged out successfully");
    localStorage.removeItem("token");
    window.location.reload(true);
    navigate("/login");
  };

  const menuItems = [
    { icon: RxPerson, text: "Profile", id: 1 },
    { icon: HiOutlineShoppingBag, text: "Deposit Money", id: 2 },
    { icon: HiOutlineReceiptRefund, text: "Withdraw Money", id: 3 },
    // { icon: AiOutlineMessage, text: "Inbox", id: 4, link: "/inbox" }, // Uncomment if needed
    { icon: MdOutlineTrackChanges, text: "Track Rating", id: 5 },
    { icon: RiLockPasswordLine, text: "Change Password", id: 6 },
    { icon: TbAddressBook, text: "Address", id: 7 },
    user && user?.role === "Admin" && { icon: MdOutlineAdminPanelSettings, text: "Admin Dashboard", id: 8, link: "/admin/dashboard" },
    { icon: AiOutlineLogin, text: "Log out", id: 9, action: logoutHandler },
  ].filter(Boolean);

  return (
    <div className="w-150 md:w-full bg-white shadow-sm rounded-[10px] p-4 pt-8">
      {menuItems.map((item) => (
        <div
          key={item.id}
          className="flex items-center cursor-pointer w-full mb-8"
          onClick={() => {
            if (item.action) {
              item.action();
            } else {
              setActive(item.id);
              if (item.link) {
                navigate(item.link);
              }
            }
          }}
        >
          <item.icon size={24} className="md:text-gray-800" color={active === item.id ? "red" : "currentColor"} />
          <span className={`pl-3 ${active === item.id ? "text-[red]" : ""} hidden md:block`}>
            {item.text}
          </span>
        </div>
      ))}
    </div>
  );
};

export default ProfileSidebar;