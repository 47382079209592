import React, { useState } from "react";
import axios from "axios";
import { server } from "../../server";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import styles from "../../styles/styles";
import { useParams } from "react-router-dom";

function RateHotel() {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const { user } = useSelector((state) => state.user);

  const [open, setOpen] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const { id } = useParams();

  const reviewHandler = async (e) => {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .put(
        `${server}/product/create-new-review`,
        {
          user,
          rating,
          comment,
          productId: id,
          orderId: id,
          
          
        },
        config
      )
      .then((res) => {
        toast.success(res.data.message);
        
        setComment("");
        setRating(null);
        setOpen(false);
        setErrorMessage("");
        window.location.href = '/popular-hotels'
      })
      .catch((response) => {
        toast.error(response.response.data.error);
        
      });
  };


  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Rate a Hotel</h2>
       {/* ratings */}
       <h5 className="pl-3 text-[20px] font-[500]">
              Give a Rating <span className="text-red-500">*</span>
            </h5>
            <div className="flex w-full ml-2 pt-1">
              {[1, 2, 3, 4, 5,6,7,8,9,10].map((i) =>
                rating >= i ? (
                  <AiFillStar
                    key={i}
                    className="mr-1 cursor-pointer"
                    color="rgb(246,186,0)"
                    size={25}
                    onClick={() => setRating(i)}
                  />
                ) : (
                  <AiOutlineStar
                    key={i}
                    className="mr-1 cursor-pointer"
                    color="rgb(246,186,0)"
                    size={25}
                    onClick={() => setRating(i)}
                  />
                )
              )}
            </div>
            <br />
            <div className="w-full ml-3">
              <label className="block text-[20px] font-[500]">
                Write a comment
                
              </label>
              <textarea
                name="comment"
                required
                id=""
                cols="20"
                rows="5"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Write your expresion about this hotel!"
                className="shadow border rounded w-[95%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              ></textarea>
            </div>
            <div
              className={`${styles.button} text-white text-[20px] ml-3`}
              onClick={rating > 1 ? reviewHandler : null}
            >
              Submit
            </div>
    </div>
  );
}

export default RateHotel;
